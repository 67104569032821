<template>
  <div>
    <!-- -->
  </div>
</template>

<script>


export default {

  data() {
    return {
    };

  },
  created() {
    let query = {}
    let status = this.$route.query.status;
    let type = this.$route.query.type;
    let catId = this.$route.query.catId || '';
    if(status) {
      query = {
        ...query,
        status: status,
      }
    }
    if(type) {
      query = {
        ...query,
        type: type,
      }
    }
    if(catId) {
      query = {
        ...query,
        catId: catId,
      }
    }
    this.$router.push({
      path: '/pd',
      query: query
    })
  },
}
</script>
